.SaveListingButton {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 1em;
    margin-bottom: 1em;
}

.SaveListingButton:hover {
    cursor: pointer;
}