.DealIndicator-good {
    color: gold !important;
}

.DealIndicator-better {
    color: limegreen !important;
}

.DealIndicator-best {
    color: forestgreen !important;
}