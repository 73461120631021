.Header-company-name-vin {
    background-color: #f5f6f6 !important; 
    color: #343a40 !important; 
    padding: 0.15em 0.25em;
}

.Header-company-name-acquire {
    font-weight: bold;
    color: #f5f6f6;
}

.Header {
    color: #f5f6f6;
    padding-left: 3rem;
    padding-right: 3rem;
}

.Header a {
    color: #f5f6f6 !important;
    text-decoration: none;
}

.Header a:hover {
    color: #007bff !important;;
}

@media screen and (max-width: 540px) {
    .Header {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

.Header-svg-link {
    cursor: pointer;
}

.Header .dropdown-menu {
    background-color: #343a40 !important;
}

.Header .dropdown-menu button {
    color: #f5f6f6 !important;
}

.Header .dropdown-menu button:hover {
    background-color: #4b4d50 !important;
    color: #f5f6f6 !important;
}