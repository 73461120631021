.Footer {
    background-color: #f5f6f6;
    padding: 4rem 0 3rem 0;
    text-align: left;
    height: 100%;
}

@media screen and (max-width: 540px) {
    .Footer {
        padding: 2rem 0.5rem;
    }
}